import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { map, startWith, tap } from 'rxjs/operators';
import { ITimezone } from '../../../../../shared/models/country';
import { CountryService } from '../../../../../shared/services/country.service';
import { IClientContactForm } from '../../../models/opportunity.models';

interface ICountry {
  abbreviation: string;
  country: string;
}

interface ICountryOptions {
  countries: string[];
  timezones: ITimezone[];
}

@Component({
  selector: 'app-client-contact-form',
  templateUrl: './client-contact-form.component.html',
  styleUrls: ['./client-contact-form.component.scss'],
})
export class ClientContactFormComponent implements AfterViewInit {
  @Input() clientContact?: Partial<IClientContactForm> & {
    options: IClientContactForm['options'];
  };
  @Output() submitClientContact = new EventEmitter<IClientContactForm>();

  sendInviteEmail: boolean;

  clientContactsForm = new UntypedFormGroup({
    firstName: new FormControl(null, Validators.required),
    lastName: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    country: new FormControl(null, [
      Validators.required,
      this.validateCountry.bind(this),
    ]),
    timezone: new FormControl(null, Validators.required),
  });

  get emailControl(): FormControl {
    return this.clientContactsForm.get('email') as FormControl;
  }

  get countryControl(): FormControl {
    return this.clientContactsForm.get('country') as FormControl;
  }

  get timezoneControl(): FormControl {
    return this.clientContactsForm.get('timezone') as FormControl;
  }

  countryOptions$ = this.countryControl.valueChanges.pipe(
    startWith(''),
    map((countryName) => this.buildOptions(countryName)),
    tap(({ timezones }) => this.resetTimezoneControl(timezones))
  );

  constructor(
    private countryService: CountryService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    if (this.clientContact) {
      this.clientContactsForm.patchValue({
        firstName: this.clientContact.firstName,
        lastName: this.clientContact.lastName,
        email: this.clientContact.email,
        country: this.findCountryByAbbr(this.clientContact.country),
      });
      this.timezoneControl.patchValue(
        this.clientContact.timezone ? this.clientContact.timezone.name : ''
      );
      if (this.clientContact?.email) {
        this.emailControl.disable();
      }
    }

    this.sendInviteEmail = this.clientContact
      ? this.clientContact.options.sendInviteEmail
      : true;

    this.changeDetector.detectChanges();
  }

  onSubmit(): void {
    if (this.clientContactsForm.valid) {
      this.submitClientContact.emit({
        ...this.clientContact,
        ...this.clientContactsForm.value,
        email: `${this.emailControl.value}`.toLowerCase().trim(),
        country: this.getCountryCode(this.countryControl.value),
        timezone: this.getTimezone(
          this.countryControl.value,
          this.timezoneControl.value
        ),
        primary: this.clientContact && this.clientContact.primary,
        options: {
          sendInviteEmail: this.sendInviteEmail,
        },
      });
    }
  }

  private buildOptions(countryName: string): ICountryOptions {
    return {
      countries: this.filterCountries(countryName),
      timezones: this.filterTimezones(countryName),
    };
  }

  private filterCountries(countryName: string): string[] {
    return this.countryService.countryList
      .map((c) => c.country)
      .filter(
        (option) =>
          option.toLowerCase().indexOf(countryName.toLowerCase()) === 0
      );
  }

  private filterTimezones(countryName: string): ITimezone[] {
    const country = this.findCountryByName(countryName);
    return country
      ? this.countryService.getTimezonesForCountry(country.abbreviation)
      : [];
  }

  private findCountryByName(name: string): ICountry | undefined {
    return this.countryService.countryList.find((c) => c.country === name);
  }

  private findCountryByAbbr(abbr: string): string {
    const country = this.countryService.countryList.find(
      (c) => c.abbreviation === abbr
    );

    return country ? country.country : '';
  }

  private getCountryCode(countryName: string): string {
    const country = this.findCountryByName(countryName);

    return country ? country.abbreviation : '';
  }

  private getTimezone(
    countryName: string,
    timezoneName: string
  ): ITimezone | {} {
    const countryCode = this.getCountryCode(countryName);

    return (
      this.countryService
        .getTimezonesForCountry(countryCode)
        .find((t) => t && t.name === timezoneName) || {}
    );
  }

  private validateCountry(ctrl: AbstractControl): ValidationErrors | null {
    const found = this.countryService.countryList.find(
      (c) => c.country === ctrl.value
    );
    return found
      ? null
      : {
          countryRequired: true,
        };
  }

  private resetTimezoneControl(timezones: ITimezone[]): void {
    this.timezoneControl.patchValue(null);
    if (!timezones.length) {
      this.timezoneControl.disable();
    } else {
      this.timezoneControl.enable();
    }
  }
}
