<div class="appMessages" data-test-id="toast-notification">
  <ng-container *ngFor="let message of messages$ | async">
    <div class="alert" *ngIf="!message.dismissed" [ngClass]="{
                    'alert-info': message.style === 'info',
                    'alert-warning alert-with-icon' : message.style === 'error', 'alert-with-icon' : message.style === 'error',
                    'alert-success' : message.style === 'success',
                    'alert-primary': message.style === 'primary',
                    'alert-danger alert-with-icon' : message.style === 'danger', 'alert-with-icon' : message.style === 'error'
                    }">
      <ng-container *ngIf="message.style === 'error' || message.style === 'danger'">
        <mat-icon>add_alert</mat-icon>
      </ng-container>
      <button class="close" (click)="message.dismissNotification()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-progress-bar mode="determinate" [value]="message.countdown"></mat-progress-bar>
      <span data-test-id="toast-notification-content">{{message.content}}</span>
    </div>
  </ng-container>
</div>