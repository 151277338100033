import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ConnectUser,
  IAuthRole,
  UserJobTitle,
  UserRoleType,
} from '@techspert-io/auth';
import { IClient } from '@techspert-io/clients';
import { ITimezone } from '../../../../shared/models/country';
import { CountryService } from '../../../../shared/services/country.service';
import { IAuthUserCreateRequest } from '../../models/user.models';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFormComponent implements OnInit {
  @Input() userData?: ConnectUser = null;
  @Input() clients: IClient[] = [];
  @Input() authRoles: IAuthRole[] = [];
  @Input() isLoading: boolean = false;
  @Output() submitUser = new EventEmitter<IAuthUserCreateRequest>();

  possibleZones: ITimezone[] = [];
  jobTitles: UserJobTitle[] = [
    'associate',
    'senior-associate',
    'associate-project-manager',
    'senior-project-manager',
    'internal',
  ];
  userRoleOpts: UserRoleType[] = ['projectUser', 'clientAdmin'];
  officeLocations: ConnectUser['profile']['officeLocation'][] = [
    'cambridge',
    'houston',
    'santiago',
  ];
  isSubmitted = false;

  userForm: FormGroup = new FormGroup(
    {
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      emailAddress: new FormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      userType: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
      timezone: new FormControl(null, Validators.required),
      clients: new FormControl([]),
      jobTitle: new FormControl(null),
      phoneNumber: new FormControl(null),
      linkedInUrl: new FormControl(null),
      officeLocation: new FormControl(null),
      userRole: new FormControl(this.userRoleOpts.find(Boolean)),
      authRoleIds: new FormControl([]),
    },
    [
      this.pmProfileValidator('jobTitle'),
      this.pmProfileValidator('officeLocation'),
      this.clientsValidator(),
    ]
  );

  get showPMExtraFields(): boolean {
    return ['PM'].includes(this.formValues.userType);
  }

  get showClientExtraFields(): boolean {
    return ['Client'].includes(this.formValues.userType);
  }

  get requiresClient(): boolean {
    return ['PM', 'Client', 'Service-Provider'].includes(
      this.formValues.userType
    );
  }

  get buttonText(): string {
    if (!this.userData) {
      return this.isLoading ? 'Creating...' : 'Create';
    } else {
      return this.isLoading ? 'Saving...' : 'Save';
    }
  }

  get userTypeControl(): FormControl {
    return this.userForm.get('userType') as FormControl;
  }

  get authRolesError(): string {
    return this.isSubmitted && this.userForm.get('authRoles').errors?.required;
  }

  get clientsError(): string {
    return this.isSubmitted && this.userForm.get('clients').errors?.required;
  }

  get isUserTypeSelected(): boolean {
    return !!this.formValues.userType;
  }

  get countries(): typeof this.countryService.countryList {
    return this.countryService.countryList;
  }

  private get formValues(): IAuthUserCreateRequest {
    const {
      emailAddress: email,
      clients,
      firstName,
      lastName,
      userType,
      country,
      timezone,
      jobTitle,
      phoneNumber,
      linkedInUrl,
      officeLocation,
      userRole,
      authRoleIds,
    } = this.userForm.getRawValue();

    const sanatise = (val?: string): string => (val ? `${val}`.trim() : val);
    const sanatiseEmail = (val?: string): string =>
      val ? sanatise(val).toLowerCase() : val;

    const user: IAuthUserCreateRequest = {
      email: sanatiseEmail(email),
      clientIds: (clients || []).map((c) => c.clientId),
      firstName: sanatise(firstName),
      lastName: sanatise(lastName),
      userType,
      country,
      timezone: this.getTimezoneObject(timezone),
      userRoles:
        userType === 'Client'
          ? [userRole].filter(Boolean)
          : this.userData?.userRoles || [],
      profile:
        userType === 'PM'
          ? {
              jobTitle,
              phoneNumber: sanatise(phoneNumber),
              linkedInUrl: sanatise(linkedInUrl),
              officeLocation,
            }
          : {},
      authRoleIds: authRoleIds.filter(Boolean),
    };

    if (this.userData) {
      return {
        ...user,
        connectId: this.userData.connectId,
      };
    }

    return user;
  }

  constructor(private countryService: CountryService) {}

  ngOnInit(): void {
    this.userForm
      .get('country')
      .valueChanges.subscribe(
        (iso2: string) =>
          iso2 &&
          (this.possibleZones =
            this.countryService.getTimezonesForCountry(iso2))
      );

    if (this.userData) {
      this.setupClientsControl(this.userData);
      this.prepopulateForm(this.userData);
      this.userTypeControl.disable();
      this.userForm.get('emailAddress').disable();
    }
  }

  submitForm(): void {
    this.isSubmitted = true;
    if (this.userForm.valid) {
      this.submitUser.emit(this.formValues);
    }
  }

  private setupClientsControl(userData: ConnectUser): void {
    if (userData.userType !== 'Expert') {
      this.userForm.patchValue({
        clients: userData.clientIds
          .map((id) => this.clients.find((c) => c.clientId === id))
          .filter(Boolean),
      });
    }
  }

  private getTimezoneObject(timezone: string): ITimezone {
    return this.possibleZones.find((zoneObj) => zoneObj.name === timezone);
  }

  private prepopulateForm(userData: ConnectUser): void {
    const { profile } = userData;

    this.userForm.patchValue({
      jobTitle: profile.jobTitle,
      phoneNumber: profile.phoneNumber,
      linkedInUrl: profile.linkedInUrl,
      officeLocation: profile.officeLocation,
      firstName: userData.firstName,
      lastName: userData.lastName,
      emailAddress: userData.email,
      userType: userData.userType,
      country: userData.country,
      timezone: userData.timezone?.name,
      userRole: userData.userRoles.find(Boolean),
      authRoleIds: userData.authRoleIds.filter(Boolean),
    });
  }

  private pmProfileValidator(field: string): ValidatorFn {
    return (ctrl): ValidationErrors => {
      const formCtrl = ctrl.get(field);
      const userType = ctrl.get('userType').value;

      const isPMUser = ['PM'].includes(userType);

      if (isPMUser && !formCtrl.value) {
        formCtrl.setErrors({
          required: 'Required',
        });
        return {};
      }

      formCtrl.setErrors(null);
      return null;
    };
  }

  private clientsValidator(): ValidatorFn {
    return (ctrl): ValidationErrors => {
      const user = ctrl.value as Record<string, string | unknown[]>;
      const clientIdCtrl = ctrl.get('clients');
      const userType = ctrl.get('userType').value;

      const userRequiresClient = ['PM', 'Client', 'Service-Provider'].includes(
        userType
      );

      const clientCount =
        'clients' in user && Array.isArray(user.clients) && user.clients.length;

      if (userRequiresClient && !clientCount) {
        clientIdCtrl.setErrors({
          required: 'Required',
        });
        return {};
      }

      clientIdCtrl.setErrors(null);
      return null;
    };
  }
}
