<form [formGroup]="formGroup" class="flex flex-row gap-5">
  <mat-form-field>
    <mat-label data-test-id="user-filter-user-label">Filter by user</mat-label>
    <input matInput formControlName="name" data-test-id="user-filter-user" />
  </mat-form-field>
  <mat-form-field>
    <mat-label data-test-id="user-filter-client-label">Filter by client</mat-label>
    <input matInput formControlName="client" [matAutocomplete]="auto" data-test-id="user-filter-client" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="clientDisplayFn">
      <mat-option *ngFor="let client of filteredClients$ | async" [value]="client"
        data-test-id="user-filter-client-option">
        {{client.clientName}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field data-test-id="user-filter-user-type">
    <mat-label data-test-id="user-filter-user-type-label">Filter by user type</mat-label>
    <mat-select multiple formControlName="userType" data-test-id="user-filter-user-type-input">
      <mat-option value="PM" data-test-id="user-filter-user-type-option">PM</mat-option>
      <mat-option value="Client" data-test-id="user-filter-user-type-option">Client</mat-option>
      <mat-option value="Expert" data-test-id="user-filter-user-type-option">Expert</mat-option>
      <mat-option value="Service-Provider" data-test-id="user-filter-user-type-option">
        Service Provider
      </mat-option>
      <mat-option value="Initiative-Only" data-test-id="user-filter-user-type-option">
        Initiatives
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>