<div class="flex justify-start content-center items-center flex-row gap-2.5">
  <app-omnisearch-icons [icon]="omnisearchResponse.entity"
    [matTooltip]="omnisearchResponse.entity | category | titlecase" class="basis-[35px]">
  </app-omnisearch-icons>
  <div class="flex-1">
    <div [innerHTML]="omnisearchResponse | omnisearchname | searchHighlight: searchTerm | safeHtml" class="title"
      data-test-id="omnisearch-result-name"></div>
    <div class="flex justify-start content-center items-center flex-row">
      <ul class="flex flex-row gap-[5px]">
        <ng-container
          *ngIf="omnisearchResponse.entity === 'experts' || omnisearchResponse.entity === 'solicitationExperts'">
          <li class="geography" data-test-id="omnisearch-result-expert-geography">
            {{omnisearchResponse.geographicTarget}}
          </li>
        </ng-container>
        <ng-container *ngIf="omnisearchResponse.entity === 'opportunities'">
          <ng-container *ngIf="users$ | async as users">
            <li class="person-initials" *ngFor="let connectId of omnisearchResponse.opportunityAssigneeIds">
              <span [matTooltip]="getFullName(users[connectId])"
                data-test-id="omnisearch-result-opportunity-project-manager">
                {{users[connectId] ? users[connectId].firstName.charAt(0) + ' ' + users[connectId].lastName.charAt(0) :
                '?'}}
              </span>
            </li>
          </ng-container>
        </ng-container>
      </ul>
      <ng-container
        *ngIf="omnisearchResponse.entity === 'experts' || omnisearchResponse.entity === 'solicitationExperts'">
        <div class="email-address"
          [innerHTML]="omnisearchResponse.emailAddress | searchHighlight: searchTerm | safeHtml"
          data-test-id="omnisearch-result-expert-primary-email-address">
        </div>
      </ng-container>
      <ng-container *ngIf="omnisearchResponse.entity === 'experts'">
        <ul class="expertise flex flex-row gap-[5px]">
          <li *ngFor="let expertise of omnisearchResponse.expertise" data-test-id="omnisearch-result-expert-expertise">
            {{expertise | titlecase}} </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="omnisearchResponse.entity === 'solicitationExperts'">
        <ul class="expertise flex flex-row gap-[5px]">
          <li *ngFor="let email of omnisearchResponse.allEmails" data-test-id="omnisearch-result-expert-email-address">
            {{email | titlecase}}
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
  <div class="flex flex-row justify-end content-center items-center h-full gap-5">
    <a [matTooltip]="omnisearchResponse.entity | detailsTooltip" mat-icon-button class="action-button"
      [routerLink]="omnisearchResponse | omnisearchUrl:'dashboard'"
      *ngIf="omnisearchResponse.entity === 'opportunities'">
      <mat-icon data-test-id="omnisearch-result-view-details">
        subject_rounded
      </mat-icon>
    </a>
    <a [matTooltip]="omnisearchResponse.entity | urlTooltip" mat-mini-fab class="action-button" color="primary"
      [routerLink]="omnisearchResponse | omnisearchUrl">
      <mat-icon data-test-id="omnisearch-result-go-to-item">
        arrow_forward
      </mat-icon>
    </a>
  </div>
</div>