import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  IClientContactForm,
  IOpportunityClientContact,
} from '../../../models/opportunity.models';

type ClientContact = IOpportunityClientContact | IClientContactForm;

type cbChange = (c: ClientContact[]) => void;
type cbTouch = () => void;

@Component({
  selector: 'opp-client-contacts-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: ClientContactsInputComponent,
      multi: true,
    },
  ],
  template: `<opp-client-contacts
    [clientContacts]="clientContacts"
    [sendInviteEmail]="sendInviteEmailsByDefault"
    (upsert)="upsert($event)"
    (delete)="delete($event)"
    (setPrimary)="setPrimary($event)"
  >
  </opp-client-contacts>`,
})
export class ClientContactsInputComponent implements ControlValueAccessor {
  @Input() sendInviteEmailsByDefault: boolean;
  touched = false;
  clientContacts: ClientContact[] = [];
  set contacts(val: ClientContact[]) {
    this.clientContacts = val;
  }

  onChange: cbChange = () => void 0;
  onTouch: cbTouch = () => void 0;

  writeValue(value: IOpportunityClientContact[]): void {
    this.contacts = value;
  }

  registerOnChange(fn: cbChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: cbTouch): void {
    this.onTouch = fn;
  }

  upsert(contact: IClientContactForm): void {
    const contacts = this.clientContacts.some((d) => d.email === contact.email)
      ? this.clientContacts.map((c) =>
          c.email === contact.email ? { ...c, ...contact } : c
        )
      : [...this.clientContacts, contact];

    this.handleUpdate(contacts);
  }

  delete(contact: IOpportunityClientContact): void {
    this.handleUpdate(
      this.clientContacts.filter((d) => d.email !== contact.email)
    );
  }

  setPrimary(contact: IOpportunityClientContact): void {
    this.handleUpdate(
      this.clientContacts.map((c) => ({
        ...c,
        primary: c.email === contact.email,
      }))
    );
  }

  private handleUpdate(contacts: ClientContact[]): void {
    if (!this.touched) {
      this.onTouch();
      this.touched = true;
    }
    this.onChange(contacts);
    this.contacts = contacts;
  }
}
