import { Component, Input } from '@angular/core';
import { ConnectUser } from '@techspert-io/auth';
import { UserService } from '@techspert-io/users';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OmnisearchQueryResponse } from '../../models/omnisearch.models';

@Component({
  selector: 'app-omnisearch-result',
  templateUrl: './omnisearch-result.component.html',
  styleUrls: ['./omnisearch-result.component.scss'],
})
export class OmnisearchResultComponent {
  @Input() omnisearchResponse: OmnisearchQueryResponse;
  @Input() searchTerm: string;

  users$: Observable<Record<string, ConnectUser>> = this.usersService
    .getAll({ userTypes: ['PM'] })
    .pipe(
      map((u) =>
        u.reduce<Record<string, ConnectUser>>(
          (prev, curr) => ({
            ...prev,
            [curr.connectId]: curr,
          }),
          {}
        )
      )
    );

  constructor(private usersService: UserService) {}

  getFullName(user?: Partial<ConnectUser>): string {
    if (!user) {
      return 'unknown';
    }

    return user.firstName + ' ' + user.lastName;
  }
}
