import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeWhile } from 'rxjs/operators';
import {
  IClientContactForm,
  IOpportunityClientContact,
} from '../../../models/opportunity.models';
import { ClientContactDialogComponent } from '../../dialogs/client-contact-dialog/client-contact-dialog.component';

@Component({
  selector: 'opp-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['./client-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientContactsComponent {
  @Input() clientContacts: IOpportunityClientContact[];
  @Input() sendInviteEmail: boolean = true;
  @Output() upsert = new EventEmitter<IClientContactForm>();
  @Output() delete = new EventEmitter<IOpportunityClientContact>();
  @Output() setPrimary = new EventEmitter<IOpportunityClientContact>();

  clientContactsColumns = ['name', 'email', 'country', 'timezone'];
  clientContactsCells = [...this.clientContactsColumns, 'options'];

  constructor(private dialog: MatDialog) {}

  upsertItem(data: Partial<IClientContactForm> = {}): void {
    const mappedData: Partial<IClientContactForm> & {
      options: IClientContactForm['options'];
    } = {
      ...data,
      options: {
        ...(data.options || {}),
        sendInviteEmail:
          data.options && 'sendInviteEmail' in data.options
            ? data.options.sendInviteEmail
            : this.sendInviteEmail,
      },
    };

    this.dialog
      .open<
        ClientContactDialogComponent,
        Partial<IClientContactForm>,
        IClientContactForm
      >(ClientContactDialogComponent, {
        width: '840px',
        data: mappedData,
      })
      .afterClosed()
      .pipe(takeWhile((d) => !!d))
      .subscribe((item) => this.upsert.emit(item));
  }

  deleteItem(data: IOpportunityClientContact): void {
    this.delete.emit(data);
  }

  setPrimaryItem(data: IOpportunityClientContact): void {
    this.setPrimary.emit(data);
  }
}
