import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CognitoAuthService, ConnectUser } from '@techspert-io/auth';
import {
  ExpertCallActionService,
  IExpertCallAction,
  IPendingConferenceBookingExpert,
} from '@techspert-io/conferences';
import { ExpertActionStatusMap } from '@techspert-io/expert-actions';
import {
  IOpportunity,
  OpportunitiesService,
} from '@techspert-io/opportunities';
import { UserService } from '@techspert-io/users';
import { combineLatest } from 'rxjs';
import { exhaustMap, tap } from 'rxjs/operators';
import {
  IViewConnectionInput,
  ViewConnectionComponent,
  viewConnectionDialogSize,
} from '../../shared/components/view-connection/view-connection.component';
import { StatisticsService } from '../../shared/services/statistics.service';
import { IExtendedExpertCallAction } from './dashboard-upcoming-conferences/dashboard-upcoming-conferences.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public title = 'Dashboard';
  public pendingCallActions: IExtendedExpertCallAction[] = [];
  public todaysCallActions: IExtendedExpertCallAction[] = [];
  public opportunities: IOpportunity[] = [];
  public filteredOpportunities: IOpportunity[] = [];
  public openConnectionsForUser: number;
  public showConferenceListLoader = true;
  public showOpportunitiesListLoader = true;
  public schedulePendingExperts: IPendingConferenceBookingExpert[] = [];
  public userSchedulePendingExperts: IPendingConferenceBookingExpert[] = [];
  private usersMap: Record<string, ConnectUser>;

  constructor(
    private router: Router,
    private cognitoAuthService: CognitoAuthService,
    private expertCallActionService: ExpertCallActionService,
    private titleService: Title,
    private statisticsService: StatisticsService,
    private userService: UserService,
    private opportunitiesService: OpportunitiesService,
    public dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.titleService.setTitle('CONNECT - Dashboard');

    const relevantStages: IOpportunity['stageName'][] = [
      'Not actively selling',
      'Actively selling',
      'Contract opportunity identified',
      'Initial discussion',
      'Outline proposal sent',
      'Formal contract negotiation',
      'Qualified',
      'Pre-Bid',
      'RFQ',
      'Repeat client, managed by PM team',
      'Project in progress',
      'First RFQ received',
      'Initial proposal sent',
      'First go ahead',
      'First project delivered - chasing for next',
      'Next projects being quoted and closed',
      'Stable Client - handed to PM and CS',
      'Not relevant.',
    ];

    combineLatest([
      this.opportunitiesService.query({
        stageNames: relevantStages,
        opportunityAssigneeIds: [
          this.cognitoAuthService.loggedInUser.connectId,
        ],
      }),
      this.expertCallActionService.queryCallActions({
        status: [ExpertActionStatusMap.Pending],
        ownerUserId: [this.cognitoAuthService.loggedInUser.connectId],
      }),
      this.expertCallActionService.getRequests(),
      this.userService.getAll({ userTypes: ['PM'] }),
    ])
      .pipe(
        tap(([opportunities, callActions, experts, users]) => {
          this.createUserMap(users);
          this.assignCallActions(callActions);
          this.assignPendingConferenceBookingExperts(experts);
          this.assignOpportunities(opportunities);
        })
      )
      .subscribe();
  }

  private createUserMap(users: ConnectUser[]): void {
    this.usersMap = users.reduce(
      (acc, curr) => ({ ...acc, [curr.connectId]: curr }),
      {}
    );
  }

  private assignPendingConferenceBookingExperts(
    experts: IPendingConferenceBookingExpert[]
  ): void {
    this.schedulePendingExperts = experts;
    this.userSchedulePendingExperts = experts.filter((e) =>
      (e.opportunityAssigneeIds || []).includes(
        this.cognitoAuthService.loggedInUser.connectId
      )
    );
  }

  public navigateToOpportunity(
    object: IOpportunity | IPendingConferenceBookingExpert
  ): void {
    const route = `/admin/client/${object.clientId}/opportunity/${object.opportunityId}/search/all-searches/phase/identified`;
    this.router.navigate([route]);
  }

  public navigateToScheduler(expert: IPendingConferenceBookingExpert): void {
    const route = `/admin/client/${expert.clientId}/opportunity/${expert.opportunityId}/search/all-searches/phase/${expert.connectPhase}/schedule/${expert.expertId}`;
    this.router.navigate([route]);
  }

  public filterOpportunities(term: string): void {
    this.filteredOpportunities = this.opportunities.filter((opportunity) =>
      opportunity?.opportunityName
        ? Object.values(opportunity).some((value) =>
            JSON.stringify(value).toLowerCase().includes(term.toLowerCase())
          )
        : false
    );
  }

  public viewConnection(callAction: IExtendedExpertCallAction): void {
    this.dialog
      .open<ViewConnectionComponent, IViewConnectionInput>(
        ViewConnectionComponent,
        {
          ...viewConnectionDialogSize,
          data: {
            callAction,
          },
        }
      )
      .afterClosed()
      .pipe(
        exhaustMap(() =>
          this.expertCallActionService.queryCallActions({
            status: [ExpertActionStatusMap.Pending],
          })
        ),
        tap((callActions) => this.assignCallActions(callActions))
      )
      .subscribe();
  }

  private assignOpportunities(opportunities: IOpportunity[]): void {
    const sortedOpps = opportunities.sort((a, b) =>
      a.onHold === b.onHold ? 0 : a.onHold ? 1 : -1
    );

    this.openConnectionsForUser =
      this.calculateOpenConnectionsForUser(sortedOpps);
    this.opportunities = sortedOpps;
    this.filteredOpportunities = sortedOpps;
    this.showOpportunitiesListLoader = false;
  }

  private calculateOpenConnectionsForUser(
    opportunities: IOpportunity[]
  ): number {
    return opportunities.reduce(
      (acc, opportunity) =>
        (acc += this.statisticsService.opportunityCalculator(
          'open-connections',
          opportunity
        )),
      0
    );
  }

  private assignCallActions(callActions: IExpertCallAction[]): void {
    this.pendingCallActions = callActions
      .map((c) => ({
        ...c,
        emergencyContactEmail: c.emergencyContact
          ? this.usersMap[c.emergencyContact]?.email || ''
          : '',
      }))
      .sort(
        (a, b) =>
          new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
      );

    const date = new Date();
    const tomorrow = new Date(date.getTime() + 86400000).toISOString(); // One day in ms

    this.todaysCallActions = this.pendingCallActions.filter(
      (c) =>
        c.datetime < tomorrow &&
        [c.ownerUserId, c.emergencyContact].includes(
          this.cognitoAuthService.loggedInUser.connectId
        )
    );

    this.showConferenceListLoader = false;
  }
}
