<div class="flex flex-col">
  <div class="flex justify-between w-full flex-row">
    <mat-card-title>Client Contacts</mat-card-title>
    <div *ngIf="clientContacts.length">
      <button mat-mini-fab color="primary" type="button" (click)="upsertItem()"
        data-test-id="client-contacts-add-client-contact">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <table class="ct-noPaddingTable" mat-table [dataSource]="clientContacts" data-test-id="client-contacts-table">
    <ng-container [matColumnDef]="column" *ngFor="let column of clientContactsColumns">
      <th mat-header-cell *matHeaderCellDef="let element" [style.width]="'25%'">
        {{element | clientContactColumn:column:true}}
      </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'primary': element.primary}"
        [attr.data-test-id]="'client-contacts-table-client-contact-' + column">
        {{element | clientContactColumn:column}}
      </td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button mat-icon-button type="button" [matMenuTriggerFor]="menu"
          data-test-id="client-contacts-client-contact-options-button">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item type="button" (click)="upsertItem(element)"
            data-test-id="client-contacts-client-contact-options-edit">
            <mat-icon>edit</mat-icon>
            <span data-test-id="client-contacts-client-contact-options-edit-label">Edit</span>
          </button>
          <div
            [matTooltip]="element.primary ? 'You can not delete a primary contact. Please change the primary contact first': ''">
            <button mat-menu-item type="button" (click)="deleteItem(element)"
              [disabled]="clientContacts.length === 1 || element.primary"
              data-test-id="client-contacts-client-contact-options-delete">
              <mat-icon>delete</mat-icon>
              <span data-test-id="client-contacts-client-contact-options-delete-label">Delete</span>
            </button>
          </div>
          <button mat-menu-item type="button" (click)="setPrimaryItem(element)" *ngIf="!element.primary"
            data-test-id="client-contacts-client-contact-options-make-primary">
            <mat-icon>account_circle</mat-icon>
            <span data-test-id="client-contacts-client-contact-options-make-primary-label">Make Primary</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="clientContactsCells"></tr>
    <tr mat-row *matRowDef="let row; columns: clientContactsCells;"
      [attr.data-test-id]="'client-contacts-table-row-' + row.connectId"></tr>
  </table>

  <div *ngIf="!clientContacts.length">
    <div
      class="ct-tableEmptyState flex justify-center content-center items-center flex-col gap-4 h-full w-full min-h-full min-w-full">
      <p class="ct-tableEmptyStateHeader" data-test-id="client-contacts-no-client-contacts-header">
        We need some information here people!
      </p>
      <p data-test-id="client-contacts-no-client-contacts-instructions">
        Just click the blue '+' buttons to add the data required to kick-off this project
      </p>
      <button mat-flat-button color="primary" type="button" (click)="upsertItem()"
        data-test-id="client-contacts-add-first-client-contact">
        <span data-test-id="client-contacts-add-first-client-contact-label">Add a Client Contact</span>
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

</div>