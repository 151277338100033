import { IUserBase, mockConnectUser } from '@techspert-io/auth';
import { IUpsertUserForClientContactRequest } from '@techspert-io/users';
import {
  IClientContact,
  IOpportunity,
  IOpportunityAssignee,
  IOpportunityClientContact,
  IOpportunityCreateRequest,
  IOpportunitySegmentConfigLegacy,
  IOpportunitySegmentConfigs,
  IOpportunitySegments,
  ISalesForceOpportunity,
  OpportunityConferenceProviders,
} from '../../models/opportunity.models';

export const mockOpportunitySegments: IOpportunitySegments = {
  a: {
    amount: 5,
    geography: 'uk',
    segment: 'doctor',
    hidden: false,
    active: true,
  },
  b: {
    amount: 12,
    geography: 'USA',
    segment: 'Carl',
    hidden: false,
    active: true,
  },
  c: {
    amount: 12,
    geography: 'USA',
    segment: 'Joe',
    hidden: false,
    active: false,
  },
  d: {
    amount: 6,
    geography: 'USA',
    segment: 'Bob',
    hidden: true,
    active: true,
  },
};

export const mockSegmentConfigs: IOpportunitySegmentConfigs = Object.keys(
  mockOpportunitySegments
).reduce(
  (prev, key) => ({
    ...prev,
    [key]: {
      owner: [key],
      cover: [],
    },
  }),
  {}
);

export const mockOpportunitySegmentTargetsDict = {
  uk: ['doctor'],
  USA: ['Carl', 'Bob'],
};

export const mockOpportunitySegmentGeographies = ['uk', 'USA'];

export const baseMockSegmentConfig: IOpportunitySegmentConfigLegacy = {
  screener: {
    segmentId: 'segmentId',
    surveyId: 'surveyId',
    segmentName: 'segmentName',
    mailingListId: 'mailingListId',
  },
  templateKeys: {},
  owner: ['test@techspert.io'],
  cover: ['test1@techspert.io'],
};

export const mockScreenerConfig: IOpportunitySegmentConfigs = Object.keys(
  mockOpportunitySegments
).reduce(
  (prev, key) => ({
    ...prev,
    [key]: {
      ...baseMockSegmentConfig,
      owner: key === 'a' ? undefined : baseMockSegmentConfig.owner,
      cover: [],
      screener: {
        ...baseMockSegmentConfig.screener,
        segmentId: key,
        emailSubject: `email-subject-${key}`,
        surveyId: `surveyId`,
      },
      conferenceTopic: 'topic',
      pitch: 'segment pitch',
    },
  }),
  {}
);

export const clientContactMock: IClientContact = {
  country: 'GB',
  email: 'email@email.com',
  firstName: 'firstNameValue',
  lastName: 'lastNameValue',
  primary: false,
  timezone: {
    name: 'GB',
    abbr: 'GMT',
    offset: '+00:00',
  },
};

export const clientContactUserMock: IOpportunityClientContact = {
  ...mockConnectUser,
  userType: 'Client',
  country: 'GB',
  email: 'email@email.com',
  firstName: 'firstNameValue',
  lastName: 'lastNameValue',
  primary: false,
  timezone: {
    name: 'GB',
    abbr: 'GMT',
    offset: '+00:00',
  },
};

export const expertNotesMock = 'notes!';

export const opportunityAssigneeMock: IOpportunityAssignee = {
  email: '123@email.com',
  firstName: 'Fuser',
  lastName: 'Luser',
  connectId: 'connectId',
};

export const opportunityAssigneeUserMock: IUserBase = {
  ...mockConnectUser,
  email: '123@email.com',
  firstName: 'Fuser',
  lastName: 'Luser',
  connectId: 'connectId',
};

export const salesForceOpportunityMock: ISalesForceOpportunity = {
  createSlackChannel: true,
  sfOppId: 'sfOpportunityId',
  sfAccountId: 'sfAccountId',
  clientName: 'clientName',
  clientId: 'clientIdFromDb',
  name: 'Accessory test',
  closeDate: '2021-03-14',
  countries: ['United States'],
  currency: '',
  clientContacts: [
    {
      firstName: 'first',
      lastName: 'last',
      country: 'test-country',
      email: 'some@email.com',
      primary: false,
      timezone: {
        name: 'tz',
        abbr: 'abbr',
        offset: '+01:00',
      },
    },
  ],
  ownerName: 'hello@techspert.io.developmen',
  lastModifiedDate: new Date(),
  segments: mockOpportunitySegments,
  blind: true,
  closedEmailSend: true,
  projectType: 'Qual',
  defaultSurveyProvider: 'Qualtrics',
  defaultConferenceProvider: OpportunityConferenceProviders.ZoomTechspert,
};

export const baseClientContactRequest: IUpsertUserForClientContactRequest = {
  clientContact: clientContactMock,
  opportunityData: {
    publicDisplayId: '',
    opportunityName: salesForceOpportunityMock.name,
    clientId: salesForceOpportunityMock.clientId,
  },
  sendInviteEmail: true,
  senderEmail: 'pm@example.com',
};

export const opportunityCreateRequestMock: IOpportunityCreateRequest = {
  clientId: salesForceOpportunityMock.clientId,
  sfOpportunityId: salesForceOpportunityMock.sfAccountId,
  opportunityName: salesForceOpportunityMock.name,
  countries: salesForceOpportunityMock.countries,
  clientContactRequests: [baseClientContactRequest],
  primaryContactEmail: baseClientContactRequest.clientContact.email,
  opportunityAssigneeIds: [opportunityAssigneeMock.connectId],
  closeDate: salesForceOpportunityMock.closeDate,
  opportunityOwner: salesForceOpportunityMock.ownerName,
  segments: salesForceOpportunityMock.segments,
  pmEmail: '',
  createSlackChannel: true,
};

export const opportunityMockData: IOpportunity = {
  clientId: 'clientId',
  opportunityId: 'oppId',
  opportunityName: 'testOpp',
  clientContacts: [],
  opportunityAssignees: [],
  closeDate: '2021-08-14',
  opportunityOwner: 'owner',
  countries: ['UK'],
  searches: {},
  publicDisplayId: 'publicDisplayId',
  callRecording: false,
  blind: true,
  screenshare: false,
  callTranscripts: false,
  cepLog: false,
  expertTargetQuantity: 12,
  startDate: '2021-07-29',
  projectType: 'Qual',
  conferenceReminderDefaultSettings: {
    enabled: false,
    sendEmailBeforeSeconds: [3600, 86400],
  },
  segments: mockOpportunitySegments,
  segmentConfig: mockSegmentConfigs,
  stageName: 'Actively selling',
  defaultConferenceTopic: 'Opp Conf Topic',
  defaultProjectPitch: 'Project pitch',
  anticipatedCallTime: 45,
  screenerEnabled: false,
  closedEmailSend: true,
  automatedScheduling: false,
  failedScreenerEmailSend: false,
  completedScreenerEmailSend: false,
  omnisearchHidden: false,
  omnisearchExpertNotification: true,
  screenerComparisonEnabled: false,
  createExpertTranscriptSummary: false,
  createSegmentTranscriptSummary: false,
  enhancedTranscriptionEnabled: false,
  sendExpertPaymentEmail: false,
  enrichedSchedulerInvite: false,
  inProjectReferralEnabled: false,
  profileEditEnabled: false,
  expertBlockProfileOutreachEnabled: false,
  isClientHostEnabled: false,
  excludeExpertPIIForClientEmails: false,
} as IOpportunity;

export const mockOpportunitySearches = {
  '1': {
    stats: {
      USA: {
        Carl: {
          secondFollowUp: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          identified: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          outreach: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          screener: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          scheduled: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          sentToClient: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          accepted: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          completed: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          firstFollowUp: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          outreachComplete: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
        },
        Bob: {
          secondFollowUp: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          identified: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          outreach: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          scheduled: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          sentToClient: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 1,
            blocked: 0,
            experts: 1,
          },
          accepted: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          completed: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          firstFollowUp: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          outreachComplete: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
        },
      },
      none: {
        none: {
          secondFollowUp: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          identified: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          outreach: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          scheduled: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          sentToClient: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          accepted: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          completed: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          firstFollowUp: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
          outreachComplete: {
            clientRejected: 0,
            adminRejected: 0,
            activeExperts: 0,
            blocked: 0,
            experts: 0,
          },
        },
      },
    },
    query: { service: 'manualUpload' },
  },
};
