import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IClientContactForm } from '../../../models/opportunity.models';

@Component({
  selector: 'app-client-contact-dialog',
  templateUrl: './client-contact-dialog.component.html',
  styleUrls: ['./client-contact-dialog.component.scss'],
})
export class ClientContactDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<
      ClientContactDialogComponent,
      IClientContactForm & { options: IClientContactForm['options'] }
    >,
    @Inject(MAT_DIALOG_DATA) public clientContact?: Partial<IClientContactForm>
  ) {}

  submitClientContact(clientContact: IClientContactForm): void {
    this.dialogRef.close(clientContact);
  }
}
