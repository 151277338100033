<div class="flex flex-col ct-page-container">
    <app-breadcrumbs [isLoading]="isLoading$ | async" backLink="../" location="Clients"
        title="New opportunity template">
    </app-breadcrumbs>

    <div *ngIf="client$ | async as client">
        <form [formGroup]="kickoffTemplateForm" (submit)="onSubmit(client.clientId)" class="flex flex-col gap-4">
            <mat-card>
                <mat-card-header>
                    <mat-card-title class="pb-3">Settings</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="flex gap-4 flex-1">
                        <mat-form-field class="basis-1/2">
                            <mat-label data-test-id="client-template-call-duration-label">
                                Anticipated call time (mins)
                            </mat-label>
                            <input matInput type="number" formControlName="anticipatedCallTime" placeholder="Call time"
                                data-test-id="client-template-call-duration">
                        </mat-form-field>

                        <mat-form-field class="basis-1/2">
                            <mat-label data-test-id="client-template-default-conference-provider">Default conference
                                provider</mat-label>
                            <mat-select formControlName="defaultConferenceProvider">
                                <mat-option *ngFor="let option of conferenceProviderOptions" [value]="option">
                                    {{ OpportunityConferenceProviderOptionLabels[option] }}
                                </mat-option>
                            </mat-select>
                            <mat-error> Required </mat-error>
                        </mat-form-field>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card>
                <mat-card-header>
                    <mat-card-title data-test-id="client-template-project-configuration-title">
                        Project configuration
                    </mat-card-title>
                    <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <opp-settings-form-input formControlName="settings"></opp-settings-form-input>
                </mat-card-content>
            </mat-card>

            <mat-card>
                <mat-card-header>
                    <mat-card-title class="pb-3">Options</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="flex flex-row flex-wrap gap-2.5">
                        <mat-slide-toggle formControlName="createSlackChannel"
                            matTooltip="Create a Slack Channel for the Opportunity and invite Pod Members"
                            data-test-id="client-template-options-create-slack-channel">
                            <span data-test-id="slide-toggle-label">Create Slack Channels</span>
                        </mat-slide-toggle>
                    </div>
                </mat-card-content>
            </mat-card>


            <div class="flex flex-1 justify-end gap-4">
                <mat-error *ngIf="isSubmitted && kickoffTemplateForm.invalid"
                    data-test-id="client-template-kick-off-errors">
                    Form has errors
                </mat-error>
                <button mat-raised-button color="primary" [disabled]="isLoading" type="submit"
                    data-test-id="client-template-save">
                    Save template
                </button>
            </div>
        </form>
    </div>

</div>