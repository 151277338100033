import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConnectUser } from '@techspert-io/auth';
import { ToastService } from '@techspert-io/user-alerts';
import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import {
  IAuthUserCreateRequest,
  IGetUsersRequest,
  IUpsertUserForClientContactRequest,
} from '../models/user.models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly baseUrl = '/users';
  private usersCache: Record<string, Observable<ConnectUser[]>> = {};

  constructor(
    private httpUsers: HttpClient,
    private toastService: ToastService
  ) {}

  getAll(request: IGetUsersRequest = {}): Observable<ConnectUser[]> {
    const params = new HttpParams({
      fromObject: {
        ...(request.userTypes
          ? { userTypes: request.userTypes.join(',') }
          : {}),
        ...(request.clientIds
          ? { clientIds: request.clientIds.join(',') }
          : {}),
      },
    }).toString();

    if (!this.usersCache[params] || !params) {
      this.usersCache[params] = this.httpUsers
        .get<ConnectUser[]>(params ? `${this.baseUrl}?${params}` : this.baseUrl)
        .pipe(
          map((users) => this.sortByName(users)),
          publishReplay(1),
          refCount()
        );
    }

    return this.usersCache[params];
  }

  getById(userId: string): Observable<ConnectUser> {
    return this.httpUsers.get<ConnectUser>(`${this.baseUrl}/${userId}`);
  }

  create(user: IAuthUserCreateRequest): Observable<ConnectUser> {
    return this.httpUsers.post<ConnectUser>(this.baseUrl, user).pipe(
      tap((user) =>
        this.toastService.sendMessage(`User created: ${user.email}`, 'success')
      ),
      this.handleError()
    );
  }

  update(user: IAuthUserCreateRequest): Observable<ConnectUser> {
    return this.httpUsers
      .put<ConnectUser>(`${this.baseUrl}/${user.connectId}`, user)
      .pipe(
        tap((user) =>
          this.toastService.sendMessage(
            `User updated: ${user.email}`,
            'success'
          )
        ),
        this.handleError()
      );
  }

  updateUserForClientContact(
    payload: IUpsertUserForClientContactRequest
  ): Observable<ConnectUser> {
    return this.httpUsers
      .post<ConnectUser>(`${this.baseUrl}/upsertUserForClientContact`, payload)
      .pipe(
        tap((user) =>
          this.toastService.sendMessage(
            `Client Contact Saved: ${user.email}`,
            'success'
          )
        ),
        this.handleError()
      );
  }

  private handleError<T>(): OperatorFunction<T, T> {
    return (source): Observable<T> =>
      source.pipe(
        catchError((err: HttpErrorResponse) => {
          this.toastService.sendMessage(
            err.error ? err.error.message : 'An error occurred.',
            'error'
          );
          return throwError(err);
        })
      );
  }

  private sortByName(users: ConnectUser[]): ConnectUser[] {
    return users.sort(
      (a, b) =>
        a.firstName.localeCompare(b.firstName) ||
        a.lastName.localeCompare(b.lastName)
    );
  }
}
