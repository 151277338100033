<mat-horizontal-stepper #stepper [linear]="true" [appSchedulingModalTimer]="opportunity" [emailType]="emailType">
  <mat-step [label]="conferenceStepLabel" [completed]="detailsConfirmed">
    <div class="form-container" style="position: relative" *ngIf="callAction && formInputData.action === 'schedule'"
      [appSchedulingCallTimer]="opportunity" gtagCategory="schedule_call">
      <form class="schedule-form" id="form" ngNativeValidate>
        <h3 data-test-id="schedule-form-context">
          {{expert | expertName}} &amp; {{client.clientName}}
        </h3>
        <div class="schedule-form-inputs">
          <h4 data-test-id="schedule-form-conference-details-title">1. Conference details</h4>
          <div class="form-input-row">
            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label">
                <span data-test-id="schedule-form-conference-topic-label">Conference topic </span>
                <mat-icon title="Appears in the email subject, email body, and calendar invite."
                  data-test-id="schedule-form-conference-topic-help">help_outline</mat-icon>
              </span>
              <input class="basicInput__input" name="topic" type="text" [(ngModel)]="callAction.externalName"
                data-test-id="schedule-form-conference-topic" />
              <mat-hint class="validation-warning" *ngIf="!callAction.externalName"
                data-test-id="schedule-form-conference-topic-error">
                Please provide a conference topic
              </mat-hint>
            </div>
            <div class="basicInput basicInput--width30">
              <span class="basicInput__label">
                <span data-test-id="schedule-form-internal-title-label">Internal call title </span>
                <mat-icon title="Appears on the Connect calendar and used internally."
                  data-test-id="schedule-form-internal-title-help">help_outline</mat-icon>
              </span>
              <input class="basicInput__input" name="title" type="text" [(ngModel)]="callAction.internalName"
                data-test-id="schedule-form-internal-title" />
              <mat-hint class="validation-warning" *ngIf="!callAction.internalName"
                data-test-id="schedule-form-internal-title-error">
                Please provide an internal title
              </mat-hint>
            </div>
          </div>

          <div class="form-input-row">
            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label">
                <span data-test-id="schedule-form-conference-date-label">Conference date </span>
                <mat-icon
                  title="Enter the time for the call from YOUR perspective for the date of call. ({{userTimezone}})"
                  data-test-id="schedule-form-conference-date-help">
                  help_outline
                </mat-icon>
              </span>
              <span class="datetime-span" *ngIf="!isEditingDate">
                <input style="pointer-events: none" class="basicInput__input" *ngIf="callAction.datetime" type="text"
                  value="{{callAction.datetime | momentTimezone: 'ddd Do MMM LT (zz)'}}"
                  data-test-id="schedule-form-conference-date" />
                <p *ngIf="!callAction.datetime" data-test-id="schedule-form-conference-date-not-selected">
                  No call date selected by client
                </p>
                <mat-icon title="Toggle edit conference date" (click)="toggleIsEditingData()"
                  data-test-id="schedule-form-conference-date-edit">edit</mat-icon>
              </span>
              <span class="datetime-span" *ngIf="isEditingDate">
                <input class="basicInput__input" (change)="assignDate($event.target.value)" type="datetime-local"
                  data-test-id="schedule-form-conference-date">
                <mat-icon title="Toggle edit conference date" (click)="toggleIsEditingData()"
                  data-test-id="schedule-form-conference-date-edit">
                  edit
                </mat-icon>
              </span>
              <mat-hint class="validation-warning" *ngIf="!callAction.datetime"
                data-test-id="schedule-form-conference-date-error">
                Please provide a date and time for the conference from your perspective ({{userTimezone}})
              </mat-hint>
            </div>

            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label" data-test-id="schedule-form-duration-label">Duration in minutes</span>
              <input class="basicInput__input" type="number" name="conference-length" [(ngModel)]="callAction.duration"
                data-test-id="schedule-form-duration" />
              <mat-hint class="validation-warning" *ngIf="!callAction.duration"
                data-test-id="schedule-form-duration-error">
                Please provide a duration for the conference
              </mat-hint>
            </div>

            <div class="basicInput basicInput--width30">
              <span class="basicInput__label" data-test-id="schedule-form-conference-provider-label">
                Conference provider
              </span>

              <select class="basicInput__input" name="provider" [(ngModel)]="callAction.callType"
                (change)="toggleClientHostedCall($event.target)" data-test-id="schedule-form-conference-provider">

                <option *ngFor="let option of conferenceProviderOptions" [value]="option"
                  data-test-id="schedule-form-conference-provider-option">
                  {{ OpportunityConferenceProviderOptionLabels[option] }}
                </option>
              </select>

              <mat-hint class="validation-warning" *ngIf="!callAction.callType"
                data-test-id="schedule-form-conference-provider-error">
                Please provide a conference provider
              </mat-hint>
            </div>
          </div>

          <div class="form-input-row" *ngIf="isManualCall">
            <div class="basicInput basicInput--width100">
              <span class="basicInput__label" data-test-id="schedule-form-manual-call-link-label">
                Conference link
              </span>
              <input class="basicInput__input" type="text" name="manual-call-link"
                [(ngModel)]="callAction.joinMetadata.weblink" data-test-id="schedule-form-manual-call-link"
                placeholder="Enter the call link" />
              <mat-hint class="validation-warning"
                *ngIf="!callAction.joinMetadata.weblink && callAction.callType === OpportunityConferenceProviders.ClientHosted"
                data-test-id="schedule-form-manual-call-link-error">
                Please provide a conference link
              </mat-hint>
            </div>
          </div>

          <h4 data-test-id="schedule-form-expert-details-title">2. Expert details</h4>
          <div class="form-input-row">
            <div class="basicInput basicInput--width30">
              <span class="basicInput__label" data-test-id="schedule-form-expert-country-timezone-label">
                Expert country and timezone {{expertCountrySupported ? '(Supported country)' : '(Unsupported country)'}}
              </span>
              <app-timezone-selector [timezoneName]="expertProfile.timezoneName"
                (timezoneAndCountrySignal)="updateExpertTimezoneAndCountry($event)"
                data-test-id="schedule-form-expert-country-timezone">
              </app-timezone-selector>
              <mat-hint class="validation-warning" *ngIf="!expertProfile.timezoneName"
                data-test-id="schedule-form-expert-country-timezone-error">
                Please provide the expert's country and timezone
              </mat-hint>
              <mat-hint class="validation-info" *ngIf="expertCountrySupportedChecked && !expertCountrySupported"
                data-test-id="schedule-form-expert-country-dial-in-support-text">
                This country does not support dial-in details, but you can still book the call
              </mat-hint>
            </div>
          </div>
          <h4 data-test-id="schedule-form-client-details-title">3. Client details</h4>
          <div class="form-input-row">
            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label" data-test-id="schedule-form-client-contact-first-name-label">
                Client representative first name
              </span>
              <input class="basicInput__input" name="rep-first-name" type="text"
                [(ngModel)]="primaryClientContact.firstName" data-test-id="schedule-form-client-contact-first-name" />
              <mat-hint class="validation-warning" *ngIf="!primaryClientContact.firstName"
                data-test-id="schedule-form-client-contact-first-name-error">
                Please provide a first name for the client contact
              </mat-hint>
            </div>
            <div class="basicInput basicInput--width30">
              <span class="basicInput__label" data-test-id="schedule-form-client-contact-last-name-label">
                Client representative last name
              </span>
              <input class="basicInput__input" name="rep-last-name" type="text"
                [(ngModel)]="primaryClientContact.lastName" data-test-id="schedule-form-client-contact-last-name" />
              <mat-hint class="validation-warning" *ngIf="!primaryClientContact.lastName"
                data-test-id="schedule-form-client-contact-last-name-error">
                Please provide a last name for the client contact
              </mat-hint>
            </div>
          </div>
          <div class="form-input-row">
            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label" data-test-id="schedule-form-client-contact-email-label">
                Client representative email
              </span>
              <input class="basicInput__input" name="rep-email" type="text" [(ngModel)]="primaryClientContact.email"
                data-test-id="schedule-form-client-contact-email" />
              <mat-hint class="validation-warning" *ngIf="!primaryClientContact.email"
                data-test-id="schedule-form-client-contact-email-error">
                Please provide an email for the client contact
              </mat-hint>
            </div>
            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label" data-test-id="schedule-form-client-contact-country-timezone-label">
                Client country and timezone {{clientCountrySupported ? '(Supported country)' : '(Unsupported country)'}}
              </span>
              <app-timezone-selector [timezoneName]="clientTimezoneName"
                (timezoneAndCountrySignal)="updateClientTimezoneAndCountry($event)"
                data-test-id="schedule-form-client-contact-country-timezone">
              </app-timezone-selector>
              <mat-hint class="validation-warning" *ngIf="!clientTimezoneName"
                data-test-id="schedule-form-client-contact-country-timezone-error">
                Please provide the client's country and timezone
              </mat-hint>
              <mat-hint class="validation-info" *ngIf="clientCountrySupportedChecked && !clientCountrySupported"
                data-test-id="schedule-form-client-contact-country-dial-in-support-text">
                This country does not support dial-in details, but you can still book the call
              </mat-hint>
            </div>
            <div class="basicInput basicInput--width30">
              <ng-container [ngTemplateOutlet]="emergencyContact"></ng-container>
            </div>
          </div>
        </div>
        <div class="toggle-section">
          <div class="recording-toggle-box" data-test-id="schedule-form-recording-toggle">
            <mat-icon class="payment-inactive-icon" *ngIf="!callAction.isRecordingOn">mic_off</mat-icon>
            <mat-icon class="payment-active-icon" *ngIf="callAction.isRecordingOn">mic</mat-icon>
            <p data-test-id="slide-toggle-label">Record the call?</p>
            <div class="toggle-container">
              <p data-test-id="schedule-form-recording-disabled-label">No</p>
              <mat-slide-toggle [(ngModel)]="callAction.isRecordingOn" name="record"
                data-test-id="slide-toggle-button"></mat-slide-toggle>
              <p data-test-id="schedule-form-recording-enabled-label">Yes</p>
            </div>
          </div>
          <div class="recording-toggle-box" data-test-id="schedule-form-anonymity-toggle">
            <mat-icon class="payment-inactive-icon" *ngIf="!callAction.blind">visibility</mat-icon>
            <mat-icon class="payment-active-icon" *ngIf="callAction.blind">visibility_off</mat-icon>
            <p data-test-id="slide-toggle-label">Blind?</p>
            <div class="toggle-container">
              <p data-test-id="schedule-form-anonymity-disabled-label">No</p>
              <mat-slide-toggle [(ngModel)]="callAction.blind" name="blind" data-test-id="slide-toggle-button">
              </mat-slide-toggle>
              <p data-test-id="schedule-form-anonymity-enabled-label">Yes</p>
            </div>
          </div>
          <div class="recording-toggle-box" data-test-id="schedule-form-is-client-host-toggle">
            <mat-icon class="payment-inactive-icon" *ngIf="!callAction.isClientHostEnabled">person_off</mat-icon>
            <mat-icon class="payment-active-icon" *ngIf="callAction.isClientHostEnabled">person</mat-icon>
            <p data-test-id="slide-toggle-label">Client is host?</p>
            <div class="toggle-container">
              <p data-test-id="schedule-form-is-client-host-disabled-label">No</p>
              <mat-slide-toggle [(ngModel)]="callAction.isClientHostEnabled" name="isClientHostEnabled"
                data-test-id="slide-toggle-button">
              </mat-slide-toggle>
              <p data-test-id="schedule-form-is-client-host-enabled-label">Yes</p>
            </div>
          </div>
        </div>
        <div class="schedule-form-actions">
          <button class="button button--primary" data-test-id="schedule-form-submit"
            [ngClass]="detailsConfirmed ? 'success-btn' : ''" *ngIf="!showBtnLoader"
            (click)="!detailsConfirmed ? save() : ''" [disabled]="isExpertInvalid">
            {{detailsConfirmed ? 'Booked' : 'Schedule'}}
            <mat-icon *ngIf="detailsConfirmed">done</mat-icon>
          </button>
          <mat-hint class="validation-warning" *ngIf="isExpertInvalid"
            data-test-id="schedule-form-invalid-expert-error">
            Failed to validate expert, please attempt to save the expert in the expert dialog to resolve validation
            errors
          </mat-hint>
          <button class="button button--primary" *ngIf="showBtnLoader">
            <app-shared-btn-loader></app-shared-btn-loader>
          </button>
        </div>
      </form>
    </div>

    <div class="form-container" style="position: relative" *ngIf="formInputData.action === 'reschedule'"
      [appSchedulingCallTimer]="opportunity" gtagCategory="reschedule_call">
      <form class="schedule-form" id="form" ngNativeValidate>
        <h3 data-test-id="schedule-form-context">
          {{expert | expertName}} &amp; {{client.clientName}}
        </h3>
        <div class="schedule-form-inputs">
          <h4 data-test-id="schedule-form-conference-details-title">1. Conference details</h4>
          <div class="form-input-row">
            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label">
                <span data-test-id="schedule-form-conference-topic-label">Conference topic</span>
                <mat-icon title="Appears in the email subject, email body, and calendar invite."
                  data-test-id="schedule-form-conference-topic-help">
                  help_outline
                </mat-icon>
              </span>
              <input class="basicInput__input" name="topic" type="text" [(ngModel)]="callAction.externalName"
                data-test-id="schedule-form-conference-topic" />
              <mat-hint class="validation-warning" *ngIf="!callAction.externalName"
                data-test-id="schedule-form-conference-topic-error">
                Please provide a conference topic
              </mat-hint>
            </div>
            <div class="basicInput basicInput--width30">
              <span class="basicInput__label">
                <span data-test-id="schedule-form-internal-title-label">Internal call title</span>
                <mat-icon title="Appears on the Connect calendar and used internally."
                  data-test-id="schedule-form-internal-title-help">
                  help_outline
                </mat-icon>
              </span>
              <input class="basicInput__input" name="title" type="text" [(ngModel)]="callAction.internalName"
                data-test-id="schedule-form-internal-title" />
              <mat-hint class="validation-warning" *ngIf="!callAction.internalName"
                data-test-id="schedule-form-internal-title-error">
                Please provide an internal title
              </mat-hint>
            </div>
          </div>
          <div class="form-input-row">
            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label" data-test-id="schedule-form-old-conference-date-label">
                Old conference date
              </span>
              <input class="basicInput__input" style="pointer-events: none" type="text"
                value="{{oldDate | momentTimezone: 'ddd Do MMM LT (zz)'}}"
                data-test-id="schedule-form-old-conference-date" />
            </div>
            <div class="basicInput basicInput--width30">
              <span class="basicInput__label">
                <span data-test-id="schedule-form-conference-date-label">
                  New conference date
                </span>
                <mat-icon title="Enter the time for the call from YOUR perspective. ({{userTimezone}})"
                  data-test-id="schedule-form-conference-date-help">
                  help_outline
                </mat-icon>
              </span>
              <span class="datetime-span" *ngIf="!isEditingDate">
                <input style="pointer-events: none" class="basicInput__input" *ngIf="callAction.datetime" type="text"
                  value="{{callAction.datetime | momentTimezone: 'ddd Do MMM LT (zz)'}}"
                  data-test-id="schedule-form-conference-date" />
                <p *ngIf="!callAction.datetime" data-test-id="schedule-form-new-conference-date-not-selected">
                  No new call date selected
                </p>
                <mat-icon title="Toggle edit conference date" (click)="toggleIsEditingData()"
                  data-test-id="schedule-form-conference-date-edit">
                  edit
                </mat-icon>
              </span>
              <span class="datetime-span" *ngIf="isEditingDate">
                <input class="basicInput__input" (change)="assignDate($event.target.value)" type="datetime-local"
                  data-test-id="schedule-form-conference-date">
                <mat-icon title="Toggle edit conference date" (click)="toggleIsEditingData()"
                  data-test-id="schedule-form-conference-date-edit">
                  edit
                </mat-icon>
              </span>
              <mat-hint class="validation-warning" *ngIf="!callAction.datetime"
                data-test-id="schedule-form-conference-date-error">
                Please provide a date and time for the call
              </mat-hint>
            </div>
          </div>
          <div class="form-input-row">
            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label" data-test-id="schedule-form-duration-label">
                Duration in minutes
              </span>
              <input class="basicInput__input" type="number" name="conference-length" [(ngModel)]="callAction.duration"
                data-test-id="schedule-form-duration" />
              <mat-hint class="validation-warning" *ngIf="!callAction.duration"
                data-test-id="schedule-form-duration-error">
                Please provide a duration for the conference
              </mat-hint>
            </div>
            <div class="basicInput basicInput--width30">
              <span class="basicInput__label" data-test-id="schedule-form-conference-provider-label">
                Conference provider
              </span>

              <select class="basicInput__input" name="provider" [(ngModel)]="callAction.callType" [disabled]="true"
                data-test-id="schedule-form-conference-provider">

                <option *ngFor="let option of conferenceProviderOptions" [value]="option"
                  data-test-id="schedule-form-conference-provider-option">
                  {{ OpportunityConferenceProviderOptionLabels[option] }}
                </option>
              </select>

              <mat-hint class="validation-warning" *ngIf="!callAction.callType"
                data-test-id="schedule-form-conference-provider-error">
                Please provide a conference provider
              </mat-hint>
            </div>
          </div>

          <div class="form-input-row" *ngIf="isManualCall">
            <div class="basicInput basicInput--width100">
              <span class="basicInput__label" data-test-id="schedule-form-manual-call-link-label">
                Conference link
              </span>
              <input class="basicInput__input" type="text" name="manual-call-link"
                [(ngModel)]="callAction.joinMetadata.weblink" data-test-id="schedule-form-manual-call-link"
                placeholder="Enter the call link" />
              <mat-hint class="validation-warning"
                *ngIf="!callAction.joinMetadata.weblink && callAction.callType === OpportunityConferenceProviders.ClientHosted"
                data-test-id="schedule-form-manual-call-link-error">
                Please provide a conference link
              </mat-hint>
            </div>
          </div>

          <h4 data-test-id="schedule-form-expert-details-title">2. Expert details</h4>
          <div class="form-input-row">
            <div class="basicInput basicInput--width30">
              <span class="basicInput__label" data-test-id="schedule-form-expert-country-timezone-label">
                Expert country and timezone {{expertCountrySupported ? '(Supported country)' : '(Unsupported country)'}}
              </span>
              <app-timezone-selector [timezoneName]="expertProfile.timezoneName"
                (timezoneAndCountrySignal)="updateExpertTimezoneAndCountry($event)"
                data-test-id="schedule-form-expert-country-timezone">
              </app-timezone-selector>
              <mat-hint class="validation-warning" *ngIf="!expertProfile.timezoneName"
                data-test-id="schedule-form-expert-country-timezone-error">
                Please provide the expert's country and timezone
              </mat-hint>
              <mat-hint class="validation-info" *ngIf="expertCountrySupportedChecked && !expertCountrySupported"
                data-test-id="schedule-form-expert-country-dial-in-support-text">
                This country does not support dial-in details, but you can still book the call
              </mat-hint>
            </div>
          </div>
          <h4 data-test-id="schedule-form-client-details-title">3. Client details</h4>
          <div class="form-input-row">
            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label" data-test-id="schedule-form-client-contact-first-name-label">
                Client representative first name
              </span>
              <input class="basicInput__input" name="rep-first-name" type="text"
                [(ngModel)]="primaryClientContact.firstName" data-test-id="schedule-form-client-contact-first-name" />
              <mat-hint class="validation-warning" *ngIf="!primaryClientContact.firstName"
                data-test-id="schedule-form-client-contact-first-name-error">
                Please provide a first name for the client contact
              </mat-hint>
            </div>
            <div class="basicInput basicInput--width30">
              <span class="basicInput__label" data-test-id="schedule-form-client-contact-last-name-label">
                Client representative last name
              </span>
              <input class="basicInput__input" name="rep-last-name" type="text"
                [(ngModel)]="primaryClientContact.lastName" data-test-id="schedule-form-client-contact-last-name" />
              <mat-hint class="validation-warning" *ngIf="!primaryClientContact.lastName"
                data-test-id="schedule-form-client-contact-last-name-error">
                Please provide a last name for the client contact
              </mat-hint>
            </div>
          </div>
          <div class="form-input-row">
            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label" data-test-id="schedule-form-client-contact-email-label">
                Client representative email
              </span>
              <input class="basicInput__input" name="rep-email" type="text" [(ngModel)]="primaryClientContact.email"
                data-test-id="schedule-form-client-contact-email" />
              <mat-hint class="validation-warning" *ngIf="!primaryClientContact.email"
                data-test-id="schedule-form-client-contact-email">
                Please provide an email for the client contact
              </mat-hint>
            </div>
            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label" data-test-id="schedule-form-client-contact-country-timezone-label">
                Client country and timezone {{clientCountrySupported ? '(Supported country)' : '(Unsupported country)'}}
              </span>
              <app-timezone-selector [timezoneName]="clientTimezoneName"
                (timezoneAndCountrySignal)="updateClientTimezoneAndCountry($event)"
                data-test-id="schedule-form-client-contact-country-timezone">
              </app-timezone-selector>
              <mat-hint class="validation-warning" *ngIf="!clientTimezoneName"
                data-test-id="schedule-form-client-contact-country-timezone-error">
                Please provide the client's country and timezone
              </mat-hint>
              <mat-hint class="validation-info" *ngIf="clientCountrySupportedChecked && !clientCountrySupported"
                data-test-id="schedule-form-client-contact-country-dial-in-support-text">
                This country does not support dial-in details, but you can still book the call
              </mat-hint>
            </div>
            <div class="basicInput basicInput--width30">
              <ng-container [ngTemplateOutlet]="emergencyContact"></ng-container>
            </div>
          </div>
          <h4 data-test-id="schedule-form-reason-for-reschedule-title">4. Reason for reschedule</h4>
          <div class="form-input-row">
            <div class="basicInput basicInput--width30 basicInput--marginRightMd">
              <span class="basicInput__label" data-test-id="schedule-form-reschedule-conference-reason-label">
                Reason for reschedule
              </span>
              <select class="basicInput__input" name="reschedule-conference-reason" [(ngModel)]="reasonForReschedule"
                data-test-id="schedule-form-reschedule-conference-reason">
                <option [value]="reason" *ngFor="let reason of reasonsForReschedule"
                  data-test-id="schedule-form-reschedule-conference-reason-option">
                  {{reason}}
                </option>
              </select>
              <mat-hint class="validation-warning" *ngIf="!reasonForReschedule"
                data-test-id="schedule-form-reschedule-conference-reason-error">
                Please select a reason for reschedule
              </mat-hint>
            </div>
          </div>
        </div>
        <div class="toggle-section">
          <div class="recording-toggle-box" data-test-id="schedule-form-recording-toggle">
            <mat-icon class="payment-inactive-icon" *ngIf="!callAction.isRecordingOn">mic_off</mat-icon>
            <mat-icon class="payment-active-icon" *ngIf="callAction.isRecordingOn">mic</mat-icon>
            <p data-test-id="slide-toggle-label">Record the call?</p>
            <div class="toggle-container">
              <p data-test-id="schedule-form-recording-disabled-label">No</p>
              <mat-slide-toggle [(ngModel)]="callAction.isRecordingOn" name="record"
                data-test-id="slide-toggle-button"></mat-slide-toggle>
              <p data-test-id="schedule-form-recording-enabled-label">Yes</p>
            </div>
          </div>
          <div class="recording-toggle-box"
            *ngIf="callAction.callType !== OpportunityConferenceProviders.MeetTechspert">
            <mat-icon class=" payment-inactive-icon" *ngIf="!callAction.blind">visibility</mat-icon>
            <mat-icon class="payment-active-icon" *ngIf="callAction.blind">visibility_off</mat-icon>
            <p data-test-id="schedule-form-blind-information">
              {{callAction.blind ? 'Blinded call' : 'Non-blinded call'}}
            </p>
          </div>
          <div class="recording-toggle-box">
            <mat-icon class="payment-inactive-icon" *ngIf="!callAction.isClientHostEnabled">person_off</mat-icon>
            <mat-icon class="payment-active-icon" *ngIf="callAction.isClientHostEnabled">person</mat-icon>
            <p data-test-id="slide-toggle-label">Client is host?</p>
            <div class="toggle-container">
              <p data-test-id="schedule-form-is-client-host-disabled-label">No</p>
              <mat-slide-toggle [(ngModel)]="callAction.isClientHostEnabled" name="isClientHostEnabled"
                data-test-id="slide-toggle-button">
              </mat-slide-toggle>
              <p data-test-id="schedule-form-is-client-host-enabled-label">Yes</p>
            </div>
          </div>
        </div>
        <div class="schedule-form-actions">
          <button class="button button--primary"
            [ngClass]="(detailsConfirmed && !callCancellation) ? 'success-btn' : ''" *ngIf="!showRescheduleBtnLoader"
            (click)="!detailsConfirmed ? saveReschedule() : ''" [disabled]="detailsConfirmed || isExpertInvalid"
            data-test-id="schedule-form-submit">
            {{(detailsConfirmed && !callCancellation) ? 'Rescheduled' : 'Reschedule and preview email'}}
            <mat-icon *ngIf="detailsConfirmed && !callCancellation">done</mat-icon>
          </button>
          <mat-hint class="validation-warning" *ngIf="isExpertInvalid">
            Failed to validate expert, please attempt to save the expert in the expert dialog to resolve validation
            errors
          </mat-hint>
          <button class="button button--primary" *ngIf="showRescheduleBtnLoader">
            <app-shared-btn-loader></app-shared-btn-loader>
          </button>
        </div>
      </form>
    </div>

    <div class="form-container" style="position: relative" *ngIf="formInputData.action === 'cancel'"
      [appSchedulingCallTimer]="opportunity" gtagCategory="cancel_call">
      <div class="schedule-form">
        <h3 data-test-id="schedule-form-context">
          {{expert | expertName}} &amp; {{client.clientName}}
        </h3>
        <div class="schedule-form-list flex flex-col gap-2" style="height: 618px"
          data-test-id="schedule-form-details-list">
          <h4 data-test-id="schedule-form-cancel-conference-subtitle">Cancel conference?</h4>
          <div>
            <span data-test-id="schedule-form-cancel-conference-expert-name-label">Expert:</span>
            <span data-test-id="schedule-form-cancel-conference-expert-name">{{expert | expertName}}</span>
          </div>
          <div>
            <span data-test-id="schedule-form-cancel-conference-client-name-label">Client:</span>
            <span data-test-id="schedule-form-cancel-conference-client-name">{{client.clientName}}</span>
          </div>
          <div>
            <span data-test-id="schedule-form-cancel-conference-topic-label">Topic:</span>
            <span data-test-id="schedule-form-cancel-conference-topic">{{callAction.externalName}}</span>
          </div>
          <div>
            <span data-test-id="schedule-form-cancel-conference-title-label">Title:</span>
            <span data-test-id="schedule-form-cancel-conference-title">{{callAction.internalName}}</span>
          </div>
          <div>
            <span data-test-id="schedule-form-cancel-conference-date-time-label">Date / time:</span>
            <span data-test-id="schedule-form-cancel-conference-date-time">
              {{callAction.datetime | date:'medium'}}
            </span>
          </div>
          <div>
            <span data-test-id="schedule-form-cancel-conference-duration-label">Duration:</span>
            <span data-test-id="schedule-form-cancel-conference-duration">{{callAction.duration}}</span>
          </div>
          <div>
            <span data-test-id="schedule-form-cancel-conference-provider-label">Provider:</span>
            <span data-test-id="schedule-form-cancel-conference-provider">{{callAction.callType}}</span>
          </div>
          <div>
            <span data-test-id="schedule-form-cancel-conference-anonymity-label">Blind status:</span>
            <span data-test-id="schedule-form-cancel-conference-anonymity">
              {{callAction.blind ? 'Blinded' : 'Non-blinded'}}
            </span>
          </div>

          <div>
            <span data-test-id="schedule-form-cancel-conference-reason-label">Reason for cancellation:</span>
            <div class="flex flex-row gap-2">
              <select class="basicInput__input" [(ngModel)]="reasonForCancellation"
                data-test-id="schedule-form-cancel-conference-reason">
                <option *ngFor="let reason of reasonsForCancellation" [value]="reason"
                  data-test-id="schedule-form-cancel-conference-reason-option">
                  {{reason}}
                </option>
              </select>
              <mat-hint class="validation-warning" *ngIf="!reasonForCancellation"
                data-test-id="schedule-form-cancel-conference-reason-error">
                Please select a reason for cancellation
              </mat-hint>
            </div>
          </div>
        </div>
        <div class="schedule-form-actions">
          <span>
            <button class="button button--danger" style="margin-right: 20px"
              [ngClass]="(detailsConfirmed && callCancellation) ? 'success-btn' : ''" *ngIf="!showCancelBtnLoader"
              (click)="cancelCall()" [disabled]="detailsConfirmed" data-test-id="schedule-form-submit">
              {{(detailsConfirmed && callCancellation) ? 'Cancelled' : 'Cancel and preview email'}}
              <mat-icon *ngIf="detailsConfirmed && callCancellation">done</mat-icon>
            </button>
            <button class="button button--secondary" *ngIf="showCancelBtnLoader">
              <app-shared-btn-loader></app-shared-btn-loader>
            </button>
          </span>
        </div>
      </div>
    </div>
  </mat-step>

  <mat-step label="Email Participants" [appSchedulingCallTimer]="opportunity"
    [gtagCategory]="'schedule_' + emailType + '_email'">
    <div *ngIf="callAction">
      <div [style.height]="'36px'" class="flex justify-between content-center items-center flex-row">
        <div class="flex flex-row gap-4">
          <p data-test-id="schedule-form-send-expert-reminders-help-text">
            {{opportunity.conferenceReminderDefaultSettings.enabled ?
            'Sending reminder to expert ' + hoursBeforeAutomatedReminderSend.join(' & ') + ' hours before the call' :
            'Reminders are disabled' }}
          </p>
          <mat-slide-toggle [(ngModel)]="opportunity.conferenceReminderDefaultSettings.enabled"
            data-test-id="schedule-form-send-expert-reminders">
          </mat-slide-toggle>
        </div>
      </div>

      <div class="tabs-header">
        <button [ngClass]="tabToShow === 'expert' ? 'active-btn' : ''" (click)="openTab('expert')"
          data-test-id="schedule-form-calendar-invite-recipient-tab-select">
          Expert
        </button>
        <ng-container>
          <button [ngClass]="tabToShow === 'client' ? 'active-btn' : ''" (click)="openTab('client')"
            data-test-id="schedule-form-calendar-invite-recipient-tab-select">
            Client
          </button>
          <span class="warning-span">
            <mat-icon>error_outline</mat-icon>
            <span data-test-id="schedule-form-email-all-recipients-warning-text">
              Both expert and client must be emailed.
            </span>
          </span>
        </ng-container>
      </div>
      <div [ngStyle]="{'display': tabToShow === 'expert' ? 'block' : 'none' }" class="email"
        data-test-id="schedule-form-calendar-invite-recipient-tab">
        <app-email [client]="client" [opportunity]="opportunity" [callAction]="emailCallAction" [expert]="expert"
          [expertProfile]="expertProfile" [blind]="callAction.blind" [primaryRecipient]="'expert'"
          [primaryClientContact]="primaryClientContact" [type]="emailType"
          (onEmailSent)="onEmailSent($event)"></app-email>
      </div>
      <div [ngStyle]="{'display': tabToShow === 'client' ? 'block' : 'none' }" class="email"
        data-test-id="schedule-form-calendar-invite-recipient-tab">
        <app-email [client]="client" [opportunity]="opportunity" [callAction]="emailCallAction" [expert]="expert"
          [expertProfile]="expertProfile" [blind]="callAction.blind" [primaryRecipient]="'client'"
          [primaryClientContact]="primaryClientContact" [type]="emailType"
          (onEmailSent)="onEmailSent($event)"></app-email>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>

<ng-template #emergencyContact>
  <span class="basicInput__label" data-test-id="schedule-form-emergency-contact-label">Emergency contact</span>
  <select class="basicInput__input" data-test-id="schedule-form-emergency-contact" name="emergencyContact"
    [(ngModel)]="callAction.emergencyContact">
    <option *ngFor="let user of users$ | async" data-test-id="schedule-form-emergency-contact-option"
      [value]="user.connectId">
      {{user.firstName}} {{user.lastName}}
    </option>
  </select>
  <mat-hint class="validation-warning" *ngIf="!callAction.emergencyContact"
    data-test-id="schedule-form-emergency-contact-error">
    Please provide an email for the emergency contact
  </mat-hint>
</ng-template>