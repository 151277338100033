import { Component, Input, OnInit } from '@angular/core';
import { IUserBase } from '@techspert-io/auth';
import { UserService } from '@techspert-io/users';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IOutreachTemplate } from '../../../models/outreach-templates.models';

@Component({
  selector: 'app-outreach-templates-diagnostics',
  templateUrl: './outreach-templates-diagnostics.component.html',
  styleUrls: ['./outreach-templates-diagnostics.component.scss'],
})
export class OutreachTemplatesDiagnosticsComponent implements OnInit {
  @Input() template: IOutreachTemplate;

  template$: Observable<IOutreachTemplate> = EMPTY;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.template$ = this.userService.getAll({ userTypes: ['PM'] }).pipe(
      map((users) => ({
        ...this.template,
        createdBy: this.getName(users, this.template.createdBy),
        lastUpdatedBy: this.getName(users, this.template.lastUpdatedBy),
      }))
    );
  }

  private getName(users: IUserBase[], connectId: string): string {
    const user = users.find((a) => a.connectId === connectId);
    return user ? `${user.firstName} ${user.lastName}` : 'Unknown user';
  }
}
