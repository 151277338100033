import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CognitoAuthService, ConnectUser } from '@techspert-io/auth';
import {
  ClientActions,
  IClient,
  selectAllClients,
} from '@techspert-io/clients';
import {
  ExpertSegment,
  ExpertsCreateService,
  IExpert,
  IExpertCreateRequest,
  expertSegmentList,
} from '@techspert-io/experts';
import { FileStoreService } from '@techspert-io/file-store';
import {
  IOpportunity,
  IOpportunitySegmentWithId,
  OpportunityActions,
  selectAllOpportunities,
} from '@techspert-io/opportunities';
import {
  ExpertSourcesService,
  IExpertSourceSyncCreateReq,
} from '@techspert-io/search';
import { ToastService } from '@techspert-io/user-alerts';
import { UserService } from '@techspert-io/users';
import { Observable, filter, firstValueFrom, map, mergeMap } from 'rxjs';
import { ICreateManyResponse } from '../../../shared/models/api';
import { AppService } from '../../../shared/services/app.service';
import {
  ILinkedInEnrichmentQuery,
  INPIEnrichmentQuery,
  ISearchQuery,
  ISearchRequestS3Body,
} from '../models/query';
import {
  IAfterSaveAction,
  ISearchDisplayExpert,
  ISelectOpportunityDialogData,
} from '../models/search-models';

type AfterSaveAction = 'go' | 'remain';
type PresourceExpertCreateRequest = Omit<
  IExpertCreateRequest,
  'expertSourceId'
>;
interface ICreateExpertAndSourceRes {
  redirectParams?: Record<string, string>;
  searchId: string;
  expertRes: ICreateManyResponse<IExpert>;
}

@Component({
  selector: 'app-select-opportunity-dialog',
  templateUrl: './select-opportunity-dialog.component.html',
})
export class SelectOpportunityDialogComponent implements OnInit {
  @ViewChild('OpportunityInput') opportunityInput: ElementRef;
  @ViewChild('SearchInput') searchInput: ElementRef;
  @ViewChild('ExpertTargetInput') expertTargetInput: ElementRef;
  @Output() dialogModeUpdatedOpportunitySignal =
    new EventEmitter<IAfterSaveAction>();

  clientsList$ = this.store
    .select(selectAllClients)
    .pipe(filter((clients) => !!clients.length));
  opportunityList$ = this.store
    .select(selectAllOpportunities)
    .pipe(
      map((opps) =>
        opps.filter(
          (o) =>
            o.clientId === this.client?.clientId &&
            o.stageName === 'Project in progress'
        )
      )
    );

  public selectedCount: number;
  public experts: ISearchDisplayExpert[];
  public searchList: string[] = [];
  public client?: IClient;
  public opportunity?: IOpportunity;
  public newSearchName: string;
  public query: ISelectOpportunityDialogData['query'];
  public showSendAndGoButtonLoader: boolean = false;
  public showSendAndRemainButtonLoader: boolean = false;
  public showSendAndRemainButtonSuccessMessage: boolean;

  public segments = expertSegmentList;

  public segment: ExpertSegment | '' = '';
  public opportunitySegments: IOpportunitySegmentWithId[];
  public opportunitySegment?: IOpportunitySegmentWithId;
  public connectUsers$: Observable<ConnectUser[]>;
  public expertOwner: ConnectUser;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISelectOpportunityDialogData,
    public dialogRef: MatDialogRef<SelectOpportunityDialogComponent>,
    private store: Store,
    public cognitoAuthService: CognitoAuthService,
    public router: Router,
    private toastService: ToastService,
    private expertsService: ExpertsCreateService,
    private userService: UserService,
    private expertSourcesService: ExpertSourcesService,
    private appService: AppService,
    private fileStore: FileStoreService
  ) {}

  ngOnInit(): void {
    this.experts = this.data.experts;
    this.query = this.data.query;
    this.selectedCount = this.data.selectedCount;
    if (this.data.dialogMode) {
      this.client = this.data.client;
      this.opportunity = this.data.opportunity;
      this.assignOpportunitySegmentsWithIds();
      this.searchList = Object.keys(this.opportunity.searches);
      if (this.data.searchName) {
        this.newSearchName = this.data.searchName;
      }
    } else {
      this.store.dispatch(ClientActions.fetchAllClients());
    }

    this.expertOwner = this.cognitoAuthService.loggedInUser;
    this.connectUsers$ = this.userService.getAll({ userTypes: ['PM'] });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public assignClientAndGetOpportunities(
    event: Event,
    clients: IClient[]
  ): void {
    const target = event.target as HTMLInputElement;
    this.clearClientAndOpportunitySpecificFields();
    this.client = clients.find((client) => client.clientName === target.value);

    if (this.client) {
      this.store.dispatch(
        OpportunityActions.queryOpportunities({
          query: {
            clientIds: [this.client.clientId],
            stageNames: ['Project in progress'],
          },
        })
      );
    }
  }

  public assignOpportunity(event: Event, opportunities: IOpportunity[]): void {
    const target = event.target as HTMLInputElement;
    this.clearOpportunitySpecificFields();
    this.opportunity = opportunities.find(
      (opportunity) => opportunity.opportunityName === target.value
    );

    if (!this.opportunity) {
      this.searchList = [];
    } else {
      this.searchList = Object.keys(this.opportunity.searches);
      this.assignOpportunitySegmentsWithIds();
    }
  }

  public assignSearch(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.newSearchName = target.value;
  }

  public assignExpertTarget(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.opportunitySegment = this.opportunitySegments.find(
      (expertTarget) => expertTarget.id === target.value
    );
  }

  public async sendExperts(afterSaveAction: AfterSaveAction): Promise<void> {
    try {
      const formattedSearchName = this.hyphenateSearchName(this.newSearchName);
      this.chooseCorrectLoader(afterSaveAction);
      const connectFormatExperts =
        this.getConnectFormatExperts(formattedSearchName);

      if (this.query.service === 'cognisearch') {
        await firstValueFrom(
          this.expertSourcesService.createAsyncCognisearch({
            opportunityId: this.opportunity.opportunityId,
            segment: this.segment,
            opportunitySegmentId: this.opportunitySegment.id,
            searchName: formattedSearchName,
            requestCount: this.query.count,
            type: 'async',
            query: {
              service: 'cognisearch',
              locations: this.query.locations || [],
              size: this.query.count,
              conditions: this.query.conditions,
              searchExpansion: this.query.searchExpansion,
            },
            defaultExpertData: { ownerConnectId: this.expertOwner.connectId },
          })
        );

        return this.takeAfterSaveAction(afterSaveAction, {});
      }

      if (
        this.query.service === 'expert-clone' ||
        this.query.service === 'falcon-search'
      ) {
        const { expertIds, ...query } = this.query;
        await firstValueFrom(
          this.expertSourcesService.createAsyncClone({
            opportunityId: this.opportunity.opportunityId,
            segment: this.segment,
            opportunitySegmentId: this.opportunitySegment.id,
            searchName: formattedSearchName,
            type: 'async',
            query,
            defaultExpertData: { ownerConnectId: this.expertOwner.connectId },
            expertIds: expertIds,
          })
        );

        return this.takeAfterSaveAction(afterSaveAction, {});
      }

      if (
        this.query.service === 'pdl-enrichment' &&
        this.query.pdlEnrichmentService === 'linkedin_urls'
      ) {
        await firstValueFrom(
          this.runCommercialUpload(this.query, formattedSearchName)
        );

        return this.takeAfterSaveAction(afterSaveAction, {});
      }

      const { redirectParams, searchId, expertRes } =
        await this.createExpertsAndSourceSync(
          this.query,
          formattedSearchName,
          connectFormatExperts
        );

      this.appendSearchToOpportunity({
        opportunityId: this.opportunity.opportunityId,
        searches: {
          [searchId]: {
            query: this.query,
            stats: {},
          },
        },
      });

      this.toastService.sendMessage(
        `Added ${expertRes.success.length} of ${connectFormatExperts.length} experts via commercial service to ${this.opportunity.opportunityName}`,
        'success'
      );

      this.takeAfterSaveAction(afterSaveAction, redirectParams || {});
    } catch (err) {
      this.showSendAndGoButtonLoader = false;
      this.showSendAndRemainButtonLoader = false;
      this.toastService.sendMessage(err, 'error');
    }
  }

  private runCommercialUpload(
    query: ILinkedInEnrichmentQuery,
    formattedSearchName: string
  ) {
    const expertSourceId = this.appService.createUUID();

    const requestBody: ISearchRequestS3Body = {
      profiles: query.attributes,
    };

    return this.fileStore
      .uploadFiles(
        [
          new File([JSON.stringify(requestBody)], `${expertSourceId}.json`, {
            type: 'application/json',
          }),
        ],
        'search-requests',
        'commercial-upload'
      )
      .pipe(
        map((res) => res.find((r) => r.status === 'complete')?.fileKey),
        filter(Boolean),
        mergeMap((requestFileKey) =>
          this.expertSourcesService.createCommercialUpload({
            opportunityId: this.opportunity.opportunityId,
            segment: this.segment,
            opportunitySegmentId: this.opportunitySegment.id,
            batchName: formattedSearchName,
            defaultExpertData: { ownerConnectId: this.expertOwner.connectId },
            expertSourceId,
            requestFileKey,
            requestCount: query.attributes.length,
          })
        )
      );
  }

  private async createExpertsAndSourceSync(
    query: ISearchQuery | INPIEnrichmentQuery,
    searchName: string,
    connectFormatExperts: PresourceExpertCreateRequest[]
  ): Promise<ICreateExpertAndSourceRes> {
    const expertSourceId = this.appService.createUUID();

    const expertRes = await this.bulkCreateExpertsUnderNewSearch(
      connectFormatExperts.map((e) => ({
        ...e,
        searchId: searchName,
        expertSourceId,
      }))
    );

    await this.createExpertSources(query, searchName, {
      expertSourceId,
      expertsAdded: expertRes.success.length,
      expertsFound: this.experts.length,
    });

    return {
      searchId: searchName,
      expertRes,
    };
  }

  expertOwnerCompare(a: ConnectUser, b: ConnectUser): Boolean {
    return a?.connectId === b?.connectId;
  }

  private async createExpertSources(
    query: ISearchQuery | INPIEnrichmentQuery,
    searchName: string,
    additionalData: {
      expertSourceId?: string;
      expertsAdded?: number;
      expertsFound?: number;
    }
  ): Promise<{
    batchId?: string;
    expertSourceId?: string;
    searchId?: string;
  }> {
    const basePayload = {
      opportunityId: this.opportunity.opportunityId,
      segment: this.segment,
      opportunitySegmentId: this.opportunitySegment.id,
      searchName: searchName,
    };

    if (query.service === 'pdl-enrichment') {
      const { searches, batchId } = await firstValueFrom(
        this.expertSourcesService.create<IExpertSourceSyncCreateReq>({
          ...basePayload,
          ...additionalData,
          requestCount: this.experts.length,
          type: 'sync',
          query: {
            service: query.service,
            size: this.experts.length,
          },
        })
      );

      const firstSearch = searches.find(Boolean);

      return {
        batchId,
        expertSourceId: firstSearch?.expertSourceId,
        searchId: firstSearch?.searchName || searchName,
      };
    }

    const { searches, batchId } = await firstValueFrom(
      this.expertSourcesService.create<IExpertSourceSyncCreateReq>({
        ...basePayload,
        ...additionalData,
        requestCount: query.count,
        type: 'sync',
        query: {
          conditions: query.conditions,
          locations: query.locations,
          service: query.service,
          size: query.count,
          fromIndex: 0,
          searchExpansion: query.searchExpansion,
        },
      })
    );

    const firstSearch = searches.find(Boolean);

    return {
      batchId,
      expertSourceId: firstSearch?.expertSourceId,
      searchId: firstSearch?.searchName || searchName,
    };
  }

  private getConnectFormatExperts(
    searchName: string
  ): PresourceExpertCreateRequest[] {
    return this.experts.map((searchExpert) => {
      let targetFields: Partial<IExpertCreateRequest>;

      const { expertise, affiliations, ...rest } = searchExpert.source;

      const expert = {
        ...rest,
        expertise: (expertise || []).map((name) => ({
          name,
          status: 'original' as const,
        })),
        affiliations: (affiliations || []).map((name) => ({
          name,
          status: 'original' as const,
        })),
        opportunityId: this.opportunity.opportunityId,
        recordingAuthorised: true,
        searchId: searchName,
        ownerConnectId: this.expertOwner.connectId,
      };
      if (this.opportunitySegment) {
        targetFields = {
          geographicTarget: this.opportunitySegment.geography,
          profileType: this.opportunitySegment.segment,
          opportunitySegmentId: this.opportunitySegment.id,
        };
      }

      return {
        ...expert,
        ...targetFields,
        ...(this.segment ? { expertSegment: this.segment } : {}),
      };
    });
  }

  private takeAfterSaveAction(
    afterSaveAction: 'go' | 'remain',
    queryParams: Record<string, string>
  ): void {
    this.dialogModeUpdatedOpportunitySignal.emit({
      bubbleClose: afterSaveAction === 'go',
      updatedOpportunity: this.opportunity,
    });

    switch (afterSaveAction) {
      case 'go':
        this.showSendAndGoButtonLoader = false;
        this.router.navigate(
          [
            '/admin/client/',
            this.client.clientId,
            'opportunity',
            this.opportunity.opportunityId,
          ],
          { queryParams }
        );
        this.closeDialog();
        break;
      case 'remain':
        this.showSendAndRemainButtonLoader = false;
        this.showSendAndRemainButtonSuccessMessage = true;
        break;
    }
  }

  private hyphenateSearchName(searchName: string): string {
    return searchName.trim().replace(/\s/g, '-').toLowerCase();
  }

  private chooseCorrectLoader(afterSaveAction: string): void {
    switch (afterSaveAction) {
      case 'go':
        this.showSendAndGoButtonLoader = true;
        break;
      case 'remain':
        this.showSendAndRemainButtonLoader = true;
        break;
    }
  }

  private assignOpportunitySegmentsWithIds(): void {
    this.opportunitySegments = Object.entries(this.opportunity.segments)
      .map(([key, segment]) => {
        return {
          ...segment,
          id: key,
        };
      })
      .filter((s) => s.active);
  }

  private clearClientAndOpportunitySpecificFields(): void {
    this.opportunity = null;
    this.opportunityInput.nativeElement.value = '';
    this.clearOpportunitySpecificFields();
  }

  private clearOpportunitySpecificFields(): void {
    this.newSearchName = '';
    this.searchList = [];
    this.searchInput.nativeElement.value = '';
    this.segment = '';
    this.opportunitySegment = null;
    this.opportunitySegments = [];
    this.expertTargetInput.nativeElement.value = '';
  }

  private async bulkCreateExpertsUnderNewSearch(
    experts: IExpertCreateRequest[]
  ): Promise<ICreateManyResponse<IExpert>> {
    return firstValueFrom(this.expertsService.createMany(experts));
  }

  private appendSearchToOpportunity(newSearchPayload: {
    opportunityId: string;
    searches: IOpportunity['searches'];
  }): void {
    this.store.dispatch(
      OpportunityActions.appendOpportunitySearch({
        searches: newSearchPayload,
      })
    );
  }
}
